exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/ContactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/Homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-investors-page-tsx": () => import("./../../../src/templates/InvestorsPage.tsx" /* webpackChunkName: "component---src-templates-investors-page-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/NewsArchive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-single-tsx": () => import("./../../../src/templates/NewsSingle.tsx" /* webpackChunkName: "component---src-templates-news-single-tsx" */),
  "component---src-templates-plain-text-page-tsx": () => import("./../../../src/templates/PlainTextPage.tsx" /* webpackChunkName: "component---src-templates-plain-text-page-tsx" */),
  "component---src-templates-project-one-million-tsx": () => import("./../../../src/templates/ProjectOneMillion.tsx" /* webpackChunkName: "component---src-templates-project-one-million-tsx" */),
  "component---src-templates-sector-single-tsx": () => import("./../../../src/templates/SectorSingle.tsx" /* webpackChunkName: "component---src-templates-sector-single-tsx" */),
  "component---src-templates-tech-beehive-tsx": () => import("./../../../src/templates/tech/Beehive.tsx" /* webpackChunkName: "component---src-templates-tech-beehive-tsx" */),
  "component---src-templates-tech-landing-page-tsx": () => import("./../../../src/templates/TechLandingPage.tsx" /* webpackChunkName: "component---src-templates-tech-landing-page-tsx" */),
  "component---src-templates-tech-polly-tsx": () => import("./../../../src/templates/tech/Polly.tsx" /* webpackChunkName: "component---src-templates-tech-polly-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/Footer/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/Header/Header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */)
}

